<script lang='ts'>
  import { onMount } from 'svelte'
  import { navigate } from 'svelte-routing'

  import { RESULTS_PER_PAGE } from '@/config/constants'

  import { sortLanguages } from '@/helpers/chatHelpers'
  import { fetchData } from '@/helpers/fetchHelpers'

  import ChatFilters from '@/components/chat/ChatFilters.svelte'
  import Flag from '@/components/forum/Flag.svelte'
  import IconSliders from "@/components/icons/phosphor/IconSliders.svelte";
  import UserAvatar from '@/components/social/UserAvatar.svelte'
  import Breadcrumb from '@/components/ui/Breadcrumb.svelte'
  import FakeButton from '@/components/ui/FakeButton.svelte'
  import PageInfo from '@/components/ui/PageInfo.svelte'

  import { _ } from '@/libs/i18n'

  export let iso: string

  let listOfUsers: any = [],
    allUsers: any = [],
    search = '',
    showFilters = false
  const chatPage = 'chatUsersSearc'
  let cursor = 999999
  let showMoreUsers = false
  const breads = [
    { mobileUrl: `/${iso}`, text: '', url: `/${iso}` },
    { text: 'chat.users' }
  ]

  onMount(() => {
    getChatList()
  })

  $: if (search && search.length > 1 || !search) {
    findUser()
  }

  function findUser () {
    cursor = 999999
    allUsers = []
    getChatList()
  }

  const getChatList = async () => {
    const data = await fetchData('chat/searchUsers', { cursor, search })
    if (data.users && data.myLanguages) {
      const myLanguages = data.myLanguages.map((lang: any) => lang.iso)
      listOfUsers = data.users.map((user: any) => {
        return {
          ...user,
          languages: sortLanguages(user.languages, myLanguages, iso)
        }
      })
      allUsers = [...allUsers, ...listOfUsers]
      if (data.users.length > RESULTS_PER_PAGE) {
        const lastUser = listOfUsers.pop()
        cursor = lastUser.points
        showMoreUsers = true
      } else {
        showMoreUsers = false
      }
    }
  }

  const showMoreLang = (e: MouseEvent) => {
    const element: HTMLElement | null = e.target as HTMLElement
    const elements = document.querySelectorAll('.more-languages')
    elements.forEach(each => each.classList.add('-hide'))
    if (element) {
      element.previousElementSibling?.classList.toggle('-hide')
      element.classList.toggle('-show')
    }
  }

  const showLessLang = (e: MouseEvent) => {
    const element: HTMLElement | null = e.target as HTMLElement
    if (element && element.parentNode) {
      const parentNode = element.parentNode as HTMLElement | null
      parentNode?.classList.add('-hide')
      parentNode?.nextElementSibling?.classList.add('-show')
    }
  }

</script>
<Breadcrumb {breads}>
  <h2 class='heading-mobile'>{$_('chat.users')}</h2>
  <div slot='pageHelperIcons' class='_breadcrumbicons'>
    <FakeButton onClick={() => {showFilters = !showFilters}}>
      <span class='_icon -noBorder' class:-active={showFilters}><IconSliders weight='regular' /></span>
      <span class='filterHeading _mobileOnly'>{$_('forum.filters')}</span>
    </FakeButton>
    <hr class='_mobileOnly' />
    <PageInfo kebabItem pageName='chat' />
  </div>
</Breadcrumb>
<div class='chat-list'>
  <ChatFilters active='users' {chatPage} {iso} bind:search bind:showFilters />
  <div class='chat-list-users _gap24'>
    {#each allUsers as item}
      <div
        class='chat-item'
        role='button'
        tabindex='0'
        on:click={() => navigate(`/${iso}/chat/${item.id}`)}
        on:keypress={() => {}}
      >
        <UserAvatar avatarVariant='menuAvatar' metaDataVariant='right' nameVariant='nick' user={item} />
        <div class='languages-list'>
          <span class='user-languages'>
            {#each (item.languages.slice(0, 4) || []) as lang} <!-- item.languages || -->
              {#key lang.id_language}
                <FakeButton
                  onClick={() => {navigate(`/${lang.iso}/chat/${item.id}`)}}
                >
                  <Flag id={lang.id_language} />
                </FakeButton>
              {/key}
            {/each}
          </span>
          <span class='more-languages -hide'>
            {#each (item.languages.slice(4) || []) as lang} <!-- item.languages || -->
              {#key lang.id_language}
                <FakeButton
                  onClick={() => {navigate(`/${lang.iso}/chat/${item.id}`)}}
                >
                  <Flag id={lang.id_language} />
                </FakeButton>
              {/key}
            {/each}
            {#if item.languages.length > 4}
              <FakeButton class='showLessLang' onClick={(e) => {e.stopPropagation(); showLessLang(e)}}>
                -{item.languages.length - 4}
              </FakeButton>
            {/if}
          </span>
          {#if item.languages.length > 4}
            <FakeButton class='loadMoreLang -show' onClick={(e) => {e.stopPropagation(); showMoreLang(e)}}>
              +{item.languages.length - 4}
            </FakeButton>
          {/if}
        </div>
      </div>
    {:else}
      <div class='item'>No users found.</div>
    {/each}
    {#if showMoreUsers}
      <button type='button' on:click={getChatList}>{$_('showMore')}</button>
    {/if}
  </div>
</div>

<style lang='scss'>
  ._icon {
    &.-active {
      background-color: var(--Gray-Medium);
    }
  }

  .chat-list {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .chat-list-users {
    gap: 0;
    border-top: none;
    border-radius: 0 0 2.4rem 2.4rem;
    padding-block: 0.8rem;
  }

  .chat-item {
    display: flex;
    flex-direction: row;
    gap: 1.6rem;
    padding: 1.2rem 0.8rem;
    border: none;
    cursor: pointer;

    &:hover {
      background-color: var(--Gray-Light);
      border-radius: 2.4rem;
    }
  }

  .languages-list {
    position: relative;
    display: flex;
    gap: 0.8rem;
    align-items: center;
    width: 100%;

    > :global(.loadMoreLang) {
      display: none;
    }
  }

  .user-languages {
    display: flex;
    gap: 0.4rem;
  }

  .more-languages {
    display: flex;
    flex-wrap: wrap;
    gap: 0.8rem;
    align-items: center;

    > :global(.showLessLang) {
      display: none;
    }
  }

  @media(max-width: 768px) {
    .languages-list {
      > :global(.loadMoreLang.-show) {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2.4rem;
        height: 2.4rem;
        padding: 0.2rem;
        font: var(--Regular-100);
        color: var(--Primary-Medium);
        background-color: var(--Primary-Ligther);
        border: 0.1rem solid var(--Primary-Medium);
        border-radius: 50%;

        &:hover {
          background-color: var(--Primary-Ligth);
        }
      }
    }

    .more-languages {
      position: absolute;
      top: -0.4rem;
      left: 11.4rem;
      z-index: 10;
      flex-direction: column;
      padding: 0.4rem;
      background-color: var(--main-background);
      border-radius: 0.4rem;
      box-shadow: var(--Shadow-Z);

      &.-hide {
        display: none;
      }

      > :global(.showLessLang) {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2.4rem;
        height: 2.4rem;
        padding: 0.2rem;
        font: var(--Regular-100);
        color: var(--Primary-Medium);
        background-color: var(--Primary-Ligther);
        border: 0.1rem solid var(--Primary-Medium);
        border-radius: 50%;

        &:hover {
          background-color: var(--Gray-Medium);
        }
      }
    }
  }
</style>

<script lang='ts'>
  import { link } from 'svelte-routing'

  import { fetchData } from '@/helpers/fetchHelpers'
  import { _ } from '@/helpers/i18n'
  import { toLevel } from '@/helpers/mixHelpers'

  import IconArrowRight from "@/components/icons/phosphor/IconArrowRight.svelte";
  import IconCheckCircle from "@/components/icons/phosphor/IconCheckCircle.svelte";
  import IconInfo from "@/components/icons/phosphor/IconInfo.svelte";
  import Accordion from '@/components/ui/Accordion.svelte'
  import Breadcrumb from '@/components/ui/Breadcrumb.svelte'
  import CloseButton from '@/components/ui/CloseButton.svelte'
  import FakeButton from '@/components/ui/FakeButton.svelte'
  import Overlay from '@/components/ui/Overlay.svelte'
  import PageInfo from '@/components/ui/PageInfo.svelte'
  import ProgressBar from '@/components/ui/ProgressBar.svelte'

  import { Lesson, LessonsCount, LessonsStatus, LevelName, LevelPageData, LevelProgress } from '@/definitions/langoid'
  import { currentLevelForLessons } from '@/store'

  export let level = 'A0' as LevelName,
    iso = ''

  if (level === 'A0') {
    level = $currentLevelForLessons
  }
  level = level.toUpperCase() as LevelName

  let lessons: LessonsStatus = {} as LessonsStatus
  let seenLessons: Lesson[] = []
  let notStartedLessons: Lesson[] = []
  let finishedLessons: Lesson[] = []
  let errorMessage = ''
  let counts: LessonsCount = {} as LessonsCount
  let progress: LevelProgress = {
    A0: [],
    A1: [],
    A2: [],
    B1: [],
    B2: [],
    C1: [],
    C2: []
  }
  let maxLevel: LevelName = 'A1'
  let showProgress = false
  let progressStats: any = {}
  const MAX_DEFINED_LEVEL_PROGRESS = 100

  const loadLevelProgress = async (level: LevelName) => {
    try {
      const levelLimit = limits[level]
      const tasksLimit = levelLimit.tasks
      const data = await fetchData('learn/levelProgress', { level })
      progressStats = {
        chat: Math.min(data[8], levelLimit.chat),
        lessons: data[1],
        level: data[0],
        sentences: data[3],
        tasks: {
          listen: Math.min(data[7], tasksLimit.listen),
          read: Math.min(data[6], tasksLimit.read),
          story: Math.min(data[4], tasksLimit.story),
          translate: Math.min(data[5], tasksLimit.translate)
        },
        words: data[2]
      }
    } catch (error: any) {
      errorMessage = error.getMessage()
    }
  }

  interface LimitLevels {
    chat: number,
    tasks: {
      listen: number,
      read: number,
      story: number,
      translate: number
    };
  }

  const limits: Record<string, LimitLevels> = {
    A1: { chat: 10, tasks: { listen: 5, read: 5, story: 5, translate: 10 } },
    A2: { chat: 100, tasks: { listen: 10, read: 10, story: 10, translate: 20 } },
    B1: { chat: 200, tasks: { listen: 20, read: 20, story: 15, translate: 40 } },
    B2: { chat: 400, tasks: { listen: 40, read: 40, story: 20, translate: 80 } },
    C1: { chat: 700, tasks: { listen: 100, read: 100, story: 50, translate: 10 } }
  }

  const loadData = async () => {
    try {
      const data: LevelPageData = await fetchData('learn/level', { level })
      level = data.level
      currentLevelForLessons.set(level === 'A0' ? 'A1' : level)
      maxLevel = data.maxLevel
      progress = { ...progress, ...data.levelProgress }
      lessons = {
        finished: data.lessons.filter((i: Lesson) => i.lesson_status === 'finished'),
        locked: data.lessons.filter((i: Lesson) => i.lesson_status === 'locked'),
        seen: data.lessons.filter((i: Lesson) => i.lesson_status === 'seen')
      }
      counts = {
        finished: lessons.finished.length,
        locked: lessons.locked.length,
        seen: lessons.seen.length
      }
      notStartedLessons = lessons.locked
      seenLessons = lessons.seen
      finishedLessons = lessons.finished
    } catch (error: unknown) {
      if (error instanceof Error) {
        errorMessage = error.message
      } else {
        errorMessage = 'An unknown error occurred'
        console.error('error is: ', error)
      }
    }
  }

  loadData()
  loadLevelProgress(level)
  $:if (level) {
    loadData()
    loadLevelProgress(level)
  }
  const getPercent = (progress: LevelProgress, level: LevelName): number => {
    return progress[level]?.[0] ? Math.round((progress[level][0] || 0) * 100) / 100 : 0 // Fixing number on 2 decimal
  }
</script>

<Breadcrumb breads={[{text:'',url:`/${iso}`},{text: $_('levelPage.lessons') + ' '+ (level.toUpperCase())}]}>
  <h2>{$_('levelPage.lessons')}</h2>
  <div slot='pageHelperIcons'>
    <PageInfo kebabItem pageName='levelPage' />
  </div>
</Breadcrumb>
<div class='top-heading'>
    <span class='available-levels'>
      {#each Object.keys(progress) as progressLevel}
        {#if progressLevel <= maxLevel && progressLevel !== 'A0'}
          <div class='level-wrapper' class:-completed={getPercent(progress, toLevel(progressLevel)) > 99}>
            {#if getPercent(progress, toLevel(progressLevel)) > 99}
              <span class='checked'><IconCheckCircle size='14' weight='fill' /></span>
            {/if}
            <a
              class='levelLink'
              class:-completed={getPercent(progress, toLevel(progressLevel)) > 99}
              class:-current={level.toUpperCase() === progressLevel}
              class:-started={getPercent(progress, toLevel(progressLevel)) > 0}
              href='/{iso}/lessons/{progressLevel}'
              use:link
            >{progressLevel}
              <small>{getPercent(progress, toLevel(progressLevel)) < 100 ? getPercent(progress, toLevel(progressLevel)) : 100}%</small>
            </a>
            <ProgressBar maxProgress={MAX_DEFINED_LEVEL_PROGRESS} progress={getPercent(progress, toLevel(progressLevel))} />
          </div>
        {/if}
      {/each}
    </span>
  <FakeButton onClick={() => {showProgress = !showProgress}}>
    <span class='levelProgress'><IconInfo size='20' weight='regular' /> {$_('levelProgress.levelProgress') + ' ' + level}</span>
  </FakeButton>
</div>
{#if errorMessage}
  {errorMessage}
{:else if Object.keys(lessons).length === 0}
  <p>..loading</p>
{:else}
  {#if seenLessons.length > 0}
    <Accordion show>
      <div slot='header' class='in-progress'>
        {$_('lessonsPage.startedLessons')}
        <span>{counts.seen}</span>
      </div>
      <div slot='content' class='lessons-list'>
        {#each seenLessons as lesson}
          <a class='lessonLink' href={`/${iso}/lessons/${level}/${lesson.id}`} use:link>
            {lesson.translation || lesson.name}
            <IconArrowRight weight='regular' />
          </a>
        {/each}
      </div>
    </Accordion>
  {/if}
  {#if notStartedLessons.length > 0}
    <Accordion show>
      <div slot='header' class='not-started-lessons'>
        {$_('lessonsPage.notStartedLessons')}
        <span>{counts.locked}</span>
      </div>
      <div slot='content' class='lessons-list'>
        {#each notStartedLessons as lesson}
          <a class='lessonLink' href={`/${iso}/lessons/${level}/${lesson.id}`} use:link>
            {lesson.translation || lesson.name}
            <IconArrowRight weight='regular' />
          </a>
        {/each}
      </div>
    </Accordion>
  {/if}
  {#if finishedLessons.length > 0}
    <Accordion>
      <div slot='header' class='finish-lessons'>
        {$_('lessonsPage.finishedLessons')}
        <span>{counts.finished}</span>
      </div>
      <div slot='content' class='lessons-list'>
        {#each finishedLessons as lesson}
          <a class='lessonLink' href={`/${iso}/lessons/${level}/${lesson.id}`} use:link>
            {lesson.translation || lesson.name}
            <IconArrowRight weight='regular' />
          </a>
        {/each}
      </div>
    </Accordion>
  {/if}
{/if}
{#if showProgress && level && iso && progressStats}
  <Overlay onClick={() => {showProgress = false}} variant='rightContent'>
    <div class='level-progress-header'>
      <h2 class='title'>{$_('levelProgress.levelProgress')} {level}</h2>
      <CloseButton closeVariant='overlay' onClick={() => {showProgress = false}} />
    </div>
    <div class='main-progress'>
      <ProgressBar maxProgress={MAX_DEFINED_LEVEL_PROGRESS} progress={progressStats.level} showPercents />
    </div>
    <div class='_vertical-small'>
      <div class='level-progress-page _gap24'>
        <h2 class='title'>{$_('levelProgress.required')}</h2>
        <div>{$_('levelProgress.lessonsRead')}</div>
        <div>
          <ProgressBar maxProgress={MAX_DEFINED_LEVEL_PROGRESS} progress={progressStats.lessons} showPercents />
        </div>
        <div>{$_('levelProgress.wordsLearned')}</div>
        <div>
          <ProgressBar maxProgress={MAX_DEFINED_LEVEL_PROGRESS} progress={progressStats.words} showPercents />
        </div>
        <div>{$_('levelProgress.sentencesLearned')}</div>
        <div>
          <ProgressBar maxProgress={MAX_DEFINED_LEVEL_PROGRESS} progress={progressStats.sentences} showPercents />
        </div>
      </div>
      <div class='level-progress-page _gap24'>
        <h2 class='title'>{$_('levelProgress.optional')}</h2>
        <div>{$_('levelProgress.writingTasks')}</div>
        <div>
          <ProgressBar maxProgress={limits[level].tasks?.story} progress={progressStats.tasks?.story} showNumbers />
        </div>
        <div>{$_('levelProgress.translatingTasks')}</div>
        <div>
          <ProgressBar maxProgress={limits[level].tasks?.translate} progress={progressStats.tasks?.translate} showNumbers />
        </div>
        <div>{$_('levelProgress.readingTasks')}</div>
        <div>
          <ProgressBar maxProgress={limits[level].tasks?.read} progress={progressStats.tasks?.read} showNumbers />
        </div>
        <div>{$_('levelProgress.listeningTasks')}</div>
        <div>
          <ProgressBar maxProgress={limits[level].tasks?.listen} progress={progressStats.tasks?.listen} showNumbers />
        </div>
        <div>{$_('levelProgress.chats')}</div>
        <div>
          <ProgressBar maxProgress={limits[level].chat} progress={progressStats.chat} showNumbers />
        </div>
      </div>
    </div>
  </Overlay>
{/if}

<style lang='scss'>
  .top-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  :global(.fake-button .levelProgress) {
    display: flex;
    gap: 0.4rem;
    align-items: center;
    font: var(--Medium-400);
    color: var(--Primary-Medium);
  }

  .available-levels {
    display: flex;
    gap: 0.8rem;
  }

  .level-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;

    > .checked {
      position: absolute;
      right: 0.4rem;
      z-index: 20;
      color: var(--success-text);
    }

    > .levelLink {
      z-index: 10;
      display: inline-block;
      min-width: 6.4rem;
      height: 5.4rem;
      padding: 0.8rem 1.6rem;
      font: var(--Semibold-400);
      text-align: center;
      color: var(--text-primary-color);
      background-color: var(--Gray-Medium);
      border-radius: 1.2rem;

      &:hover {
        background-color: var(--Gray-Med);
      }

      &.-started {
        color: var(--accent-text);
        background: var(--accent-background);

        &:hover {
          background-color: var(--Accent-Light);
        }
      }

      &.-completed {
        color: var(--success-text);
        background: var(--success-background);

        &:hover {
          background-color: var(--Success-Light);
        }
      }

      &.-current {
        color: var(--Base-White);
        background: var(--Primary-Medium);

        &:hover {
          background-color: var(--Primary-Dark);
        }
      }

      > small {
        display: block;
        font-size: 1.2rem;
      }
    }

    > :global(.progress-container) {
      position: absolute;
      bottom: -0.3rem;
      left: 0.6rem;
      width: 80%;
    }

    &.-completed {
      > :global(.progress-container .progress-bar-cover .progress-bar-status.-filled) {
        background-color: var(--success-text);
      }
    }
  }

  .lessons-list {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    align-items: flex-start;
    align-self: stretch;

    > .lessonLink {
      display: flex;
      gap: 0.8rem;
      align-content: space-between;
      align-self: stretch;
      justify-content: space-between;
      margin: -0.1rem 0;
      padding: 1.2rem;
      color: var(--text-primary-color);
      border: 0.1rem solid transparent;
      border-radius: 0.8rem;

      > :global(svg) {
        visibility: hidden;
      }

      &:hover {
        background: var(--hover-background);
        border: 0.1rem solid var(--Gray-Light);

        > :global(svg) {
          visibility: visible;
        }
      }
    }
  }

  .in-progress, .not-started-lessons, .finish-lessons {
    font: var(--Medium-600);

    > span {
      padding: 0.2rem 0.6rem;
      font: var(--Semibold-400);
      border-radius: 0.8rem;
    }
  }

  .in-progress {
    color: var(--Primary-Medium);

    > span {
      background: var(--Primary-Ligther);
    }
  }

  .not-started-lessons {
    color: var(--text-primary-color);

    > span {
      background: var(--text-background);
    }
  }

  .finish-lessons {
    color: var(--Success-Medium);

    > span {
      background: var(--Success-Lighter);
    }
  }

  .level-progress-page {
    display: grid;
    grid-gap: 2.4rem;
    grid-template-columns: 15rem 1fr;

    > .title {
      grid-column: 1/3;
      margin: 0;
      font-weight: bold;
    }
  }

  .level-progress-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  @media(max-width: 768px) {
    .top-heading {
      flex-direction: column;
      gap: 0.8rem;
      align-items: start;
    }
  }
</style>
